import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { estatesReducer } from './offers/store/estates.reducer';
import { EstatesEffects } from './offers/store/estates.effects';
import { CurrentEstateEffects } from './offers/store/current-estate/current-estate.effects';
import { currentEstateReducer } from './offers/store/current-estate/current-estate.reducer';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TypePage } from './contentful-content-types/page';
import { ContentfulService } from './shared/services/contentful/contentful.service';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoaderService } from './shared/services/localization/transloco-http-loader/transloco-http-loader.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
export function initializeApp(contenfulService: ContentfulService, router: Router) {
  return () =>
    new Promise((resolve) => {
      contenfulService.getPages().subscribe((pages) => {
        const routes = pages.items.map((page: TypePage) => page.fields['slug'] || '');

        const updatedRouter = router.config;
        routes.forEach((route: any) => {
          const slicedRoute = route.replace('/', '');
          updatedRouter?.push({
            path: slicedRoute,
            loadComponent: () => import('./dynamic-page/dynamic-page.component').then((m) => m.DynamicPageComponent)
            // you could add any data from the server here and get it from your component
          });
        });

        router.resetConfig([...updatedRouter]);
      });
      setTimeout(() => {
        resolve(true);
      });
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'de',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode()
      },
      loader: TranslocoHttpLoaderService
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),

    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ContentfulService, Router]
    },
    importProvidersFrom(StoreModule.forRoot()),
    importProvidersFrom(StoreModule.forFeature('router', routerReducer)),
    importProvidersFrom(StoreModule.forFeature('estates', estatesReducer)),
    importProvidersFrom(StoreModule.forFeature('currentEstate', currentEstateReducer)),

    importProvidersFrom(EffectsModule.forFeature([EstatesEffects])),
    importProvidersFrom(EffectsModule.forFeature([CurrentEstateEffects])),

    importProvidersFrom(StoreRouterConnectingModule.forRoot()),
    importProvidersFrom(EffectsModule.forRoot()),
    importProvidersFrom(
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        connectInZone: true
      })
    ),

    provideLottieOptions({
      player: () => player
    })
  ]
};
